import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useInView } from "react-intersection-observer"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Projects from "../../components/projects"

const PuebloSonidero = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const { puebloSonideroCloudinary } = useStaticQuery(graphql`
    query {
      puebloSonideroCloudinary: allCloudinaryMedia(
        filter: { public_id: { regex: "/rgrq/pueblosonidero/" } }
      ) {
        edges {
          node {
            secure_url
          }
        }
      }
    }
  `)

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <Layout>
      <Header />
      <div className="project">
        <div className="container-fluid subheader">
          <div className="row">
            <div className="col-lg-4">
              <h2>05. Pueblo Sonidero</h2>
            </div>
            <div className="offset-lg-1 col-lg-3">
              <h2>2020</h2>
            </div>
            <div className="col-lg-4">
              <h2>Book Design, Identity, Typeface Design</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-12 hero hero-pueblosonidero">
              <div className={`hero-footer ${inView}`}>
                <div className="row justify-content-between align-items-end">
                  <div className="col-lg-5 d-none d-md-block">
                    <h2>
                      <Link to="/projects/artcenter-library">
                        Previous Project(04) ↖
                      </Link>
                    </h2>
                    <h2>
                      <Link to="/projects/pueblo-sonidero-mediatecture">
                        Next Project(06) ↗
                      </Link>
                    </h2>
                  </div>
                  <div className="col-md-3 d-none d-md-block">
                    <h2>
                      <Link to="/">rg-rq.com</Link>
                    </h2>
                  </div>
                  <div className="offset-md-2 col-md-2">
                    <button onClick={scrollTop}>
                      <h2>
                        Back up
                        {inView ? (
                          <img
                            className="img-fluid"
                            src="/Up.svg"
                            alt="cheveron icon"
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src="/Up-white.svg"
                            alt="cheveron icon"
                          />
                        )}
                      </h2>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid content">
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Sonidero (disc jockey)</p>
            </div>
            <div className="col-lg-7">
              <p>
                Sonidero (disc jockey) is a popular social phenomenon
                originating in Mexico City. The term has different meanings
                including the sound player, the disc jockey, and the
                entertainer. They are characterized by sending shout-outs
                throughout public street dances and/or dance events; they
                specialize in tropical music and other Latin rhythms. Their main
                stage is the streets.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[1].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[2].node.secure_url}
                alt=""
              />
            </div>
          </div>
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Origin</p>
            </div>
            <div className="col-lg-7">
              <p>
                Although the term Sonidero is a generic term, there is no
                authorship of the person who defined the term that is considered
                popular to define such a person, the term was massively adopted
                around the 1970s, originally they were called “tocadiscos” or
                disc player.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[3].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[4].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[5].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[6].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[7].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[8].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[9].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[10].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[11].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[12].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[13].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[14].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[15].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[16].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[17].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[18].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[19].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[20].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[21].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[22].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[23].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[24].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[25].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[26].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[27].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[28].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[29].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[30].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[31].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[32].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[33].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[34].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[35].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[36].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[37].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[38].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[39].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[40].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[41].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[42].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[43].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[44].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[45].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[46].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[47].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[48].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[49].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[50].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[51].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[52].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[53].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[54].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[55].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[56].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[57].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[58].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[59].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[60].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[61].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[62].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[63].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[64].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[65].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[66].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[67].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[68].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[69].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[70].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <div className="embed-responsive embed-responsive-16by9">
                <video
                  className="embed-responsive-item"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/rwh/video/upload/v1607370318/rgrq/static/pueblosonidero/72_b5yqw5.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[71].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[72].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[73].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[74].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12 col-md-6">
              <img
                className="img-fluid"
                src={puebloSonideroCloudinary.edges[75].node.secure_url}
                alt=""
              />
            </div>
          </div>
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Credits</p>
            </div>
            <div className="col-lg-7">
              <p>01.–24. Photography by Maribel Barcena Lopez</p>
            </div>
          </div>
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Special Thank You</p>
            </div>
            <div className="col-lg-7">
              <p>Brad Bartlett (Professor)</p>
              <p>Yebin Chung (TA)</p>
              <p>Sabotaje Media (@sabotaje.media)</p>
              <p>Gabriel Dueñez (@sonido.duenez)</p>
              <p>Mazzie Miles (Professor)</p>
              <p>Ivan Cruz (Professor)</p>
              <p>Taka Iwai</p>
              <p>Rolando Castillo </p>
              <p>Yair Sarmiento </p>
              <p>Yasodari Sanchez</p>
              <p>Mas Exitos con Ganas (Dub Lab)</p>
              <p>Stephen Serrato</p>
              <p>Rachel Julius</p>
              <p>Mirjam Wirz</p>
              <p>A&G Engraving</p>
              <p>A1 Bookbinding</p>
              <p>Universidad Autónoma de Nuevo León</p>
            </div>
          </div>
        </div>
      </div>
      <div ref={ref}></div>
      <Projects />
    </Layout>
  )
}
export default PuebloSonidero
